import React from "react";
import * as styles from "./Content.module.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

const Content = ({ title, description }) => { 
    return (
        <div className={`${styles.BannerCard}  ${styles.BannerCard_2}`}>
        <div className={styles.firstBox}>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            <h2 className="animate__animated animate__fadeInUp">{title}</h2>
            </AnimationOnScroll>
            <div>
            <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            <p className="animate__animated animate__fadeInUp">{description}</p>
            </AnimationOnScroll>
            </div>
        </div>
        
    </div>
    );
};

export default Content;