import classNames from "classnames";
import React from "react";
import * as styles from "./HeroSection.module.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';
import bannerImg from '../../assets/images/NewsBanner.png'

const HeroSection = ({ title, reporter, image }) => { 
  return (
    <div
      className={classNames(
        styles.BannerSection, styles.pressSection
      )}
    >
      <div
        className={classNames(styles.TextContent)}
      >
        <h6>{reporter}</h6>
        <AnimationOnScroll animateIn="animate__animated animate__fadeInUp">
          <h2 className="animate__animated animate__fadeInUp">{title}
          </h2>
        </AnimationOnScroll>
      </div>

      <div className={styles.bannerContent}>
        <img className={styles.bannerImg} src={image?.url || image?.data?.attributes?.url} alt="" />
      </div>
    </div>
  );
};

export default HeroSection;
