import React from "react";
import * as styles from "./DescriptionSection.module.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import ReactMarkdown from "react-markdown";
import 'animate.css';

const DescriptionSection = ({description}) => {   
  return (
    <div
      className={styles.HighlightText}
    >
      <AnimationOnScroll animateIn="animate__animated animate__fadeInUp">
        <h1 className="animate__animated animate__fadeInUp">
          <ReactMarkdown>{description?.data?.descriptionCard || description}</ReactMarkdown>
        </h1>
      </AnimationOnScroll>
    </div>
  );
};

export default DescriptionSection;
