import React from "react";
import * as styles from "./ContentWithImage.module.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';
import classNames from "classnames";
import bannerImg from "../../assets/images/NewsImg2.png"

const ContentWithImage = ({title, description,image}) => {
 
    return (
        <div className={`${styles.BannerCard}  ${styles.BannerCard_2}`}>
        <div className={styles.firstBox}>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            <h2 className="animate__animated animate__fadeInUp">{title}</h2>
            </AnimationOnScroll>
            <div>
            <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            <p className="animate__animated animate__fadeInUp">{description}
</p>
            </AnimationOnScroll>
            </div>
        </div>
        <div className={styles.secondContent}>
        {bannerImg ? <img src={image?.url || image?.data?.attributes?.url} alt="" /> : null}
        </div>
        
    </div>
    );
};

export default ContentWithImage;