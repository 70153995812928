import React from "react";
import * as styles from "./MidSection.module.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';
import classNames from "classnames";
import bannerImg from "../../assets/images/secondimage.png"

const MidSection = ({paragraph_2 = true, paragraph_3 = true, title, description,description2,description3,image }) => { 
    return (
        <div className={`${styles.BannerCard}  ${styles.BannerCard_2}`}>
        <div className={styles.firstBox}>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            <h2 className="animate__animated animate__fadeInUp">{title}</h2>
            </AnimationOnScroll>
            <div>
            <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            <p className="animate__animated animate__fadeInUp">{description}</p>
            </AnimationOnScroll>
            </div>
        </div>
        <div className={styles.secondContent}>
        {bannerImg ? <img src={image?.url || image?.data?.attributes?.url} alt="" /> : null}
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
        {paragraph_2 ? <p className={classNames("animate__animated animate__fadeInUp")}>{description2}</p> : null}
        {paragraph_3 ? <p className="animate__animated animate__fadeInUp">{description3}</p> : null}  
        </AnimationOnScroll>
        </div>
        
    </div>
    );
};

export default MidSection;