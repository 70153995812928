// extracted by mini-css-extract-plugin
export var BannerSection = "HeroSection-module--BannerSection--d182c";
export var TextContent = "HeroSection-module--TextContent--083cb";
export var TextContent2 = "HeroSection-module--TextContent2--68c21";
export var TextContent3 = "HeroSection-module--TextContent3--c7f76";
export var VisitContent = "HeroSection-module--VisitContent--1fc3c";
export var bannerContent = "HeroSection-module--bannerContent--d93ef";
export var bannerImg = "HeroSection-module--bannerImg--3681f";
export var displayNone = "HeroSection-module--displayNone--3dbdf";
export var pressSection = "HeroSection-module--pressSection--9f1b7";
export var tenutaParra = "HeroSection-module--tenutaParra--0cb5d";
export var viniSection = "HeroSection-module--viniSection--14e0c";