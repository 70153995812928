import React, {useContext } from "react";
import HeroSection from '../../Components/HeroSection/HeroSection';
import DescriptionSection from '../../Components/DescriptionSection/DescriptionSection';
import MidSection from '../../Components/MidSection/MidSection';
import ContentWithImage from '../../Components/ContentWithImage/ContentWithImage';
import Content from '../../Components/Content/Content';
import TextWithButton from '../../Components/TextWithButton/TextWithButton';
import { graphql } from 'gatsby';
import Header, {languageContext} from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';  

const NewsDetail = ({data}) => { 
   
    const llanguage = useContext(languageContext);
    const { language } = llanguage; 
    const { strapiLatestNews } = data  || {}; 
    const italianData = strapiLatestNews?.localizations?.data && strapiLatestNews?.localizations?.data?.map((item,index)=> {
      const blocks = item?.attributes?.Blocks;
      return blocks
    });
    const blocks = language === "en" ? strapiLatestNews?.Blocks : italianData;  
    
       const configEN = [
        {
          strapi_component: "blocks.blocks",
          component: ({ title, reporter,image }) => (<HeroSection title={title} reporter={reporter} image={image} />
          ),
        },
        {
            strapi_component: "blocks.description-section",
            component: ({descriptionCard }) => (<DescriptionSection description={descriptionCard} />
            ),
          },
        {
            strapi_component: "blocks.text-with-image",
            component: ({ title, description,description2,description3,image }) => (<MidSection title={title} description={description} description2={description2} description3={description3} image={image} />
            ),
          },

          {
            strapi_component: "blocks.cta",
            component: ({ title, description,image }) => (<ContentWithImage title={title} description={description}  image={image} />
            ),
          },

          {
            strapi_component: "blocks.content",
            component: ({ title, description }) => (<Content title={title} description={description}  />
            ),
          },

          // {
          //   strapi_component: "blocks.text-with-button",
          //   component: ({ description ,button }) => (<TextWithButton description={description} button={button} />
          //   ),
          // },  
    ];


    const configIT = [
      {
        _xcomponent: "blocks.blocks",
        component: ({ title, reporter,image }) => (<HeroSection title={title} reporter={reporter} image={image} />
        ),
      },
      {
        _xcomponent: "blocks.description-section",
          component: ({descriptionCard }) => (<DescriptionSection description={descriptionCard} />
          ),
        },
      {
        _xcomponent: "blocks.text-with-image",
          component: ({ title, description,description2,description3,image }) => (<MidSection title={title} description={description} description2={description2} description3={description3} image={image} />
          ),
        },

        {
          _xcomponent: "blocks.cta",
          component: ({ title, description,image }) => (<ContentWithImage title={title} description={description}  image={image} />
          ),
        },

        {
          _xcomponent: "blocks.content",
          component: ({ title, description }) => (<Content title={title} description={description}  />
          ),
        },

        // {
        //   _xcomponent: "blocks.text-with-button",
        //   component: ({ description ,button }) => (<TextWithButton description={description} button={button} />
        //   ),
        // },
        

        
  ];
   const finalDataEn = language == "en" && blocks && blocks?.map((currentBlock) => {
    const currentENConfig = configEN.find((configItem) => currentBlock.strapi_component === configItem.strapi_component);
    if (currentENConfig) {
      return {
        ...currentBlock,
        Component: (params) => currentENConfig.component(params),
      }
    }
    return null;
  }).filter(Boolean);

  const finalDataIt = language == "it" && blocks && blocks[0]?.map((currentBlock) => { 
    const currentITConfig = configIT.find((configItem) => currentBlock._xcomponent === configItem._xcomponent); 
    if (currentITConfig) {
      return {
        ...currentBlock,
        Component: (params) => currentITConfig.component(params),
      }
    }
    return null;
  }).filter(Boolean);


  const finalData = language=="it"  ? finalDataIt : finalDataEn;
      const renderContent = () => (
        <div>
          {finalData && finalData.map((elm, index) => (
            <div key={index}>
              {elm.Component({
                title: elm.title,
                reporter: elm.reporter,
                image:elm.image,
                description:elm.description,
                description2:elm.description2,
                description3:elm.description3,
                button:elm.button,
                descriptionCard:elm.descriptionCard
                
              })}
            </div>
          ))}
        </div>
      );


    return (
        <div>
        <Header />
        {renderContent()}
        <Footer/>
      </div>
    );
};

export default NewsDetail;




export const query = graphql`
query MyQueryNewsDetail ($slug: String){
    strapiLatestNews (slug: {eq: $slug}) { 
      slug
      Blocks {
        ... on STRAPI__COMPONENT_BLOCKS_BLOCKS {
          id
          strapi_component
          title
          reporter
          image {
            url
          }
        }
        ... on STRAPI__COMPONENT_BLOCKS_TEXT_WITH_IMAGE {
          id
          strapi_component
          strapi_id
          title
          description
          description2
          description3
          image {
            url
          }
        }
        ... on STRAPI__COMPONENT_BLOCKS_CTA {
          strapi_component
          strapi_id
          title
          description
          image {
            url
          }
        }
        ... on STRAPI__COMPONENT_BLOCKS_DESCRIPTION_SECTION {
          strapi_id
          strapi_component
          descriptionCard{
            data {
              descriptionCard
            }
          }
        }
        ... on STRAPI__COMPONENT_BLOCKS_CONTENT {
          strapi_id
          strapi_component
          title
          description
        }
        
      }

      localizations {
        data {
          attributes {
            image {
              data {
                attributes {
                  url
                }
              }
            }
            title
            reporter
            Blocks {
              _xcomponent 
              descriptionCard
              title
              reporter 
              description
              description2
              description3
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
             
            }
          }
        }
      }
    }  
      
  } 
`